import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";

import handwash from "../../static/img/hand-wash.png"
import donotwring from "../../static/img/do-not-wring.png"
import dryflat from "../../static/img/dry-flat.png"
import tumbledry from "../../static/img/do-not-tumble-dry.png"

// eslint-disable-next-line
export const CarePageTemplate = ({ title,  image }) => {
    const heroImage = getImage(image) || image;

  return (
    <>
      <FullWidthImage img={heroImage} title={title} />
      <section className="section care">
        <p><img alt="handwash" src={handwash} /> Hand wash with care in warm water using mild soap</p>
        <p><img alt="do not wring" src={donotwring} /> Do not wring</p>
        <p><img alt="dry flat" src={dryflat} /> Dry Flat</p>
        <p><img alt="do not tumbledry" src={tumbledry} /> Do Not Tumble dry</p>
      </section>    
    </>
  );
};

CarePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const CarePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CarePageTemplate
        title={post.frontmatter.title}
        image={post.frontmatter.image}
      />
    </Layout>
  );
};

CarePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CarePage;

export const carePageQuery = graphql`
  query CarePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
